<template>
  <el-container>
    <el-main>
      <el-card shadow="always"
        ><img src="/images/logo.png" style="width: 250px; margin-top: 15px" />
        <el-divider></el-divider>
        <i
          class="el-icon-circle-check"
          style="color: green; font-size: 4em"
        ></i>
        <h3>Tudo certo!</h3>
        <h4 class="text-muted">
          Aguarde para que um administrador autorize sua entrada
        </h4>
      </el-card>
    </el-main>
  </el-container>
</template>
<script>
import AuthService from "../services/auth";
export default {
  name: "AfterLogin",
  data: () => ({
    requestRepeater: null,
  }),
  mounted() {
    this.requestRepeater = setInterval(this.checkAuthentication, 2000);
  },
  unmounted() {
    clearInterval(this.requestRepeater);
  },
  methods: {
    async checkAuthentication() {
      const { session } = await AuthService().session();

      if (!session?.pending_auth?.length) this.$router.push("/inicio");
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 500px;
  margin: auto;
}
.text-muted {
  color: grey;
}
.el-input {
  margin-top: 15px;
}
.el-switch {
  margin-top: 15px;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
